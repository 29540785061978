<template>
  <div
    class="navigation fixed top-0 left-0 flex flex-col pt-2 px-6 h-full overflow-y-auto bg-surface-no-contrast sm:bg-surface-min-contrast sm:py-4 sidemenu:opacity-100 sidemenu:z-0"
    :class="open ? ['opacity-100', 'z-50'] : ['opacity-0', 'z-[-1]']"
  >
    <div class="flex items-center justify-between mb-8 sidemenu:justify-center">
      <router-link class="sm:p-4" to="/">
        <inline-svg
          class="text-text-logo w-auto h-4 dark:text-text-primary"
          :src="require('@/assets/images/osgenic-logo.svg')"
        ></inline-svg>
      </router-link>
      <a class="p-2 -mr-2 text-text-variant sidemenu:hidden" @click="$emit('hide')">
        <icon-fluent class="h-6 w-6" name="dismiss"></icon-fluent>
      </a>
    </div>
    <div v-if="!$currentUser" class="bg-surface-min-contrast rounded p-md flex flex-col items-center">
      <div class="mt-lg text-body-medium text-center">{{ $t("subscriptions.login_or_signup_text") }}</div>
      <div class="mt-lg mb-md">
        <router-link class="btn btn-primary" to="/sessions/login">{{ $t("actions.login") }}</router-link>
      </div>
    </div>
    <nav v-if="$currentUser" class="w-64 mx-auto flex flex-col capitalize sidemenu:w-full">
      <nav-link
        v-if="$hasRole('hospital_admin')"
        to="/dashboard"
        icon="chartMultiple"
        :title="$t('dashboard.title')"
      ></nav-link>
      <nav-link v-if="$hasFeature('home')" to="/home" icon="home" :title="$t('home.title')"></nav-link>
      <nav-link to="/contents" icon="grid" :title="$t('contents.title')"></nav-link>
      <nav-link to="/invivo" :title="'Invivo Anatomy'">
        <template #icon>
          <inline-svg :src="require('@/assets/images/invivo.svg')"></inline-svg>
        </template>
      </nav-link>
      <nav-folder
        v-if="$currentUser.showEducation()"
        icon="hatGraduation"
        :title="$t('education.title')"
        path="/education"
      >
        <nav-sublink
          v-if="$hasRole('hospital_admin')"
          to="/education/logbooks"
          icon="clipboardText"
          :title="$t('logbooks.title')"
        ></nav-sublink>
        <nav-sublink
          v-if="$hasRole('resident') || $currentUser.isTerminatedResident()"
          to="/education/my_logbook/entries"
          :title="$t('logbook.title')"
        ></nav-sublink>
        <nav-sublink to="/education/quizzes" :title="$t('quizzes.title')"></nav-sublink>
      </nav-folder>
      <nav-link
        v-if="$hasRole('osgenic_admin')"
        to="/organizations"
        icon="buildingMultiple"
        :title="$t('organizations.title')"
      ></nav-link>
      <nav-link
        v-if="$hasRole('hospital_admin')"
        to="/hospital_users"
        icon="peopleSettings"
        :title="$t('user_management.title')"
      ></nav-link>
      <nav-link
        v-if="$hasRole('hospital_admin')"
        to="/residents"
        icon="peopleTeam"
        :title="$t('residents.title')"
      ></nav-link>
      <nav-link
        v-if="$hasRole('osgenic_admin')"
        to="/user_management"
        icon="peopleSettings"
        :title="$t('user_management.title')"
      ></nav-link>
      <nav-link
        v-if="$hasRole('osgenic_admin')"
        to="/viewer"
        icon="viewDesktop"
        title="Viewer"
        target="_blank"
      ></nav-link>
      <nav-link class="mt-12 sidemenu:hidden" to="/profile" icon="person" :title="$t('profile.title')"></nav-link>
      <nav-link
        class="sidemenu:hidden"
        icon="signOut"
        :title="$t('actions.log_out')"
        @click.native="logout()"
      ></nav-link>
    </nav>
    <nav class="nav-footer flex-1 flex flex-col justify-end items-start w-64 mt-12 mb-3 mx-auto sidemenu:w-full">
      <feedback-button> </feedback-button>
      <nav-bottom-link to="/eula" icon="document" :title="$t('pages.terms_of_service')"> </nav-bottom-link>
      <nav-bottom-link to="/privacy_statement" icon="document" :title="$t('pages.privacy_policy')"></nav-bottom-link>
    </nav>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import NavLink from "@/components/navigation/nav-link.vue";
import { logout } from "@/actions";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    NavLink,
  },
})
export default class Navigation extends Vue {
  @Prop()
  open: boolean;

  logout() {
    logout();
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  width: var(--sidemenu-width);

  .secondary-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    a {
      min-width: 0;
    }
  }

  .nav-footer {
    .links {
      display: flex;
      flex-direction: row;
      margin-top: var(--spacing-sm);
    }
  }

  .small-device-secondary-nav {
    display: none;

    @media (max-width: $main-column-max-width) {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
