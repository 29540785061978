import VueRouter, { RouteConfig, Route } from "vue-router";
import NotFoundPage from "@/pages/not-found-page.vue";
import ContentsPage from "@/pages/contents/contents-page.vue";
import PageShowPage from "@/pages/page/page-show-page.vue";
import PageContentPage from "@/pages/page/page-content-page.vue";
import PageEditPage from "@/pages/page/page-edit-page.vue";
import MyLogbookPage from "@/pages/my-logbook-page.vue";
import OrganizationsPage from "@/pages/organizations-page.vue";
import OrganizationPage from "@/pages/organization-page.vue";
import OrganizationEditPage from "@/pages/organization-edit-page.vue";
import AddOrganizationPage from "@/pages/add-organization-page.vue";
import UsersPage from "@/pages/users/users-page.vue";
import UserListPage from "@/pages/users/user-list-page.vue";
import InvitationListPage from "@/pages/users/invitation-list-page.vue";
import AddUserFromTokenPage from "@/pages/add-user-from-token-page.vue";
import InvitationJoinPage from "@/pages/invitation-join-page.vue";
import ProfilePage from "@/pages/profile/profile-page.vue";
import LoginPage from "@/pages/login-page.vue";
import ResidentsPage from "@/pages/residents-page.vue";
import ResidentLogbookPage from "@/pages/resident-logbook-page.vue";
import LogbooksPage from "@/pages/logbooks-page.vue";
import EditLogbookPage from "@/pages/edit-logbook-page.vue";
import RequestPasswordResetPage from "@/pages/request-password-reset-page.vue";
import ResetPasswordPage from "@/pages/reset-password-page.vue";
import LogbookEntriesPage from "@/pages/logbook-entries-page.vue";
import LogbookCurriculumPage from "@/pages/logbook-curriculum-page.vue";
import DashboardPage from "@/pages/dashboard/dashboard-page.vue";
import CustomerOrganizationPage from "@/pages/customer-organization/customer-organization-page.vue";
import CustomerOrganizationUsersPage from "@/pages/customer-organization/customer-organization-users-page.vue";
import CustomerOrganizationInvitationsPage from "@/pages/customer-organization/customer-organization-invitations-page.vue";
import PrivacyStatementPage from "@/pages/privacy-statement-page.vue";
import EULAPage from "@/pages/eula-page.vue";
import CookieListPage from "@/pages/cookie-list-page.vue";
import QuizzesPage from "@/pages/quizzes-page.vue";
import QuizEditPage from "@/pages/quiz-edit/quiz-edit-page.vue";
import QuizPage from "@/pages/quiz-page.vue";
import SharePage from "@/pages/share/share-page.vue";
import PublicContentPage from "@/pages/public/public-content-page.vue";
import SignUpPage from "@/pages/sign-up/sign-up-page.vue";
import VerifyEmailPage from "@/pages/verify-email-page.vue";
import Welcome from "@/pages/welcome.vue";
import MembershipExpiredPage from "@/pages/membership-expired/memberships-expired-page.vue";
import WaitingApprovalListPage from "@/pages/users/waiting-approval-list-page.vue";
import ExternalLinkEditPage from "@/pages/external-link/external-link-edit-page.vue";
import HomePage from "@/pages/home/home-page.vue";
import SnowplowService from "@/services/snowplow-service";
import store from "@/store/store";
import AnalyticsEventsService from "./services/analytics-events";
import ChoosePlanView from "@/pages/sign-up/choose-plan-view.vue";
import CreateAccountView from "@/pages/sign-up/create-account-view.vue";
import AddPaymentMethodView from "@/pages/sign-up/add-payment-method-view.vue";
import WelcomeView from "@/pages/sign-up/welcome-view.vue";
import OrganizationGeneralPage from "@/pages/organization-general-page.vue";
import InvivoPage from "@/pages/invivo/invivo-page.vue";

const routes: RouteConfig[] = [
  {
    path: "/",
    redirect: () => {
      const currentUser = store.getters.currentUser;
      if (currentUser) {
        if (currentUser.isHospitalAdmin()) {
          return { name: "Dashboard" };
        } else if (currentUser.isTerminatedResident()) {
          return { name: "MyLogbook" };
        } else if (currentUser.features?.home) {
          return { name: "Home" };
        } else {
          return { name: "Contents" };
        }
      } else {
        return "/sessions/login";
      }
    },
  },
  {
    name: "Home",
    path: "/home",
    component: HomePage,
  },
  {
    name: "Invivo",
    path: "/invivo",
    component: InvivoPage,
  },
  {
    name: "Contents",
    path: "/contents",
    alias: "",
    component: ContentsPage,
    props: (route) => ({
      back: route.query.back,
    }),
  },
  {
    name: "SharePage",
    path: "/shares/:token",
    component: SharePage,
    props: true,
  },
  {
    name: "AddPage",
    path: "/pages/new/:pageType",
    meta: { noLayout: true },
    component: PageEditPage,
    props: (route) => ({
      ...route.params,
      ...route.query,
    }),
  },
  {
    name: "EditPage",
    path: "/pages/:id/edit",
    meta: { noLayout: true },
    component: PageEditPage,
    props: (route) => ({
      id: route.params.id,
      document: route.query.document,
    }),
  },
  {
    name: "Page",
    path: "/pages/:id",
    component: PageShowPage,
    meta: { analyticsEvent: false },
    children: [
      {
        name: "PageContent",
        path: ":documentType",
        meta: { analyticsEvent: false },
        component: PageContentPage,
        props: true,
      },
    ],
  },
  {
    name: "AddExternalLink",
    path: "/external_links/new",
    component: ExternalLinkEditPage,
    props: (route) => ({
      id: route.params.id,
    }),
  },
  {
    name: "EditExternalLink",
    path: "/external_links/:id/edit",
    component: ExternalLinkEditPage,
    props: true,
  },
  { name: "Logbooks", path: "/education/logbooks", component: LogbooksPage, props: true },
  { name: "EditLogbook", path: "/education/logbooks/:id/edit", component: EditLogbookPage, props: true },
  {
    name: "Organizations",
    path: "/organizations",
    component: OrganizationsPage,
  },
  {
    name: "AddOrganization",
    path: "/organizations/new",
    component: AddOrganizationPage,
  },
  {
    name: "Organization",
    path: "/organizations/:id",
    component: OrganizationPage,
    props: true,
    children: [
      {
        path: "/",
        redirect: "general",
      },
      {
        name: "OrganizationGeneral",
        path: "general",
        component: OrganizationGeneralPage,
        props: (route) => ({
          organizationId: route.params.id,
        }),
      },
      {
        name: "OrganizationUserList",
        path: "users",
        component: UserListPage,
        props: (route) => ({
          organizationId: route.params.id,
        }),
      },
      {
        name: "OrganizationInvitationList",
        path: "invitations",
        component: InvitationListPage,
        props: (route) => ({
          organizationId: route.params.id,
        }),
      },
    ],
  },
  {
    name: "OrganizationEdit",
    path: "/organizations/:id/edit",
    component: OrganizationEditPage,
  },
  {
    path: "/hospital_users",
    component: CustomerOrganizationPage,
    children: [
      {
        path: "",
        redirect: "users",
      },
      {
        name: "CustomerOrganizationUsers",
        path: "users",
        component: CustomerOrganizationUsersPage,
      },
      {
        name: "CustomerOrganizationInvitations",
        path: "invitations",
        component: CustomerOrganizationInvitationsPage,
      },
    ],
  },
  {
    path: "/user_management",
    component: UsersPage,
    children: [
      { path: "", redirect: "users" },
      {
        path: "users",
        component: {
          render: (createElement) => createElement("router-view"),
        },
        children: [{ name: "UserList", path: "", component: UserListPage }],
      },
      { name: "InvitationList", path: "invitations", component: InvitationListPage },
      { name: "ApprovalList", path: "waiting-approval", component: WaitingApprovalListPage },
    ],
  },
  {
    name: "SignUp",
    path: "/signup",
    component: SignUpPage,
    meta: { noLayout: true },
    props: (route) => ({
      code: route.query.code,
    }),
    children: [
      { path: "", redirect: "create_account" },
      { path: "create_account", name: "SignupCreateAccount", component: CreateAccountView, meta: { noLayout: true } },
      { path: "welcome_to_osgenic", name: "SignupWelcome", component: WelcomeView, meta: { noLayout: true } },
      // Leaving this for now, if we need to show the choose plan screen again
      { path: "choose_plan", name: "SignupChoosePlan", component: ChoosePlanView, meta: { noLayout: true } },
      // {
      //   path: "add_payment_method",
      //   name: "SignupAddPaymentMethod",
      //   component: AddPaymentMethodView,
      //   meta: { noLayout: true },
      // },

      // Any other path is considered as signup code
      {
        path: ":code",
        redirect(to) {
          return { name: "SignUp", query: { code: to.params.code } };
        },
      },
    ],
  },
  {
    name: "AddUserFromToken",
    path: "/users/new/:token",
    component: AddUserFromTokenPage,
    props: true,
    meta: { noLayout: true },
  },
  {
    name: "InvitationJoin",
    path: "/invitations/join/:token",
    component: InvitationJoinPage,
    props: true,
    meta: { noLayout: true },
  },
  { name: "Residents", path: "/residents", component: ResidentsPage },
  {
    path: "/education/my_logbook",
    component: MyLogbookPage,
    props: (route) => ({
      residency_id: route.query.residency_id,
      curriculum_id: route.query.curriculum_id,
    }),
    children: [
      {
        path: "/",
        name: "MyLogbook",
        redirect: ({ params }) => {
          return { name: "MyLogbookEntries", params };
        },
      },
      {
        name: "MyLogbookEntries",
        path: "entries",
        component: LogbookEntriesPage,
      },
      {
        name: "MyLogbookCurriculum",
        path: "curriculum",
        component: LogbookCurriculumPage,
      },
    ],
  },
  {
    path: "/residents/:id/:residency_id?/logbook",
    component: ResidentLogbookPage,
    props: true,
    children: [
      {
        path: "/",
        name: "ResidentLogbook",
        redirect: ({ params }) => {
          return { name: "ResidentLogbookEntries", params };
        },
      },
      {
        name: "ResidentLogbookEntries",
        path: "entries",
        component: LogbookEntriesPage,
      },
      {
        name: "ResidentLogbookCurriculum",
        path: "curriculum/:curriculum_id",
        component: LogbookCurriculumPage,
      },
    ],
  },
  { name: "Profile", path: "/profile", component: ProfilePage },
  {
    name: "Quizzes",
    path: "/education/quizzes",
    component: QuizzesPage,
  },
  {
    name: "AddQuiz",
    path: "/education/quizzes/add",
    component: QuizEditPage,
    props: (route) => ({ ...route.params, action: "add" }),
  },
  {
    name: "EditQuiz",
    path: "/education/quizzes/:id/edit",
    component: QuizEditPage,
    props: (route) => ({ ...route.params, action: "edit" }),
  },
  {
    name: "DuplicateQuiz",
    path: "/education/quizzes/:id/duplicate",
    component: QuizEditPage,
    props: (route) => ({ ...route.params, action: "duplicate" }),
  },
  {
    name: "Quiz",
    path: "/education/quizzes/:id",
    component: QuizPage,
    props: true,
    meta: { analyticsEvent: false },
  },
  {
    name: "Login",
    path: "/sessions/login",
    component: LoginPage,
    props: true,
    meta: { noLayout: true },
  },
  {
    name: "RequestPasswordReset",
    path: "/sessions/reset_password",
    component: RequestPasswordResetPage,
    meta: { noLayout: true },
  },
  {
    name: "SubscriptionExpired",
    path: "/sessions/subscription_expired",
    component: MembershipExpiredPage,
    meta: { noLayout: true },
  },
  {
    name: "ResetPassword",
    path: "/users/reset_password/:token",
    component: ResetPasswordPage,
    meta: { noLayout: true },
  },
  {
    name: "VerifyEmail",
    path: "/users/verify_email/:token",
    component: VerifyEmailPage,
    meta: { noLayout: true },
    props: true,
  },
  {
    name: "Welcome",
    path: "/welcome",
    component: Welcome,
    meta: { noLayout: true },
    props: true,
  },
  {
    name: "Viewer",
    path: "/viewer",
    beforeEnter() {
      location.href = "/viewer";
    },
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: DashboardPage,
  },
  {
    name: "PrivacyStatement",
    path: "/privacy_statement",
    component: PrivacyStatementPage,
  },
  {
    name: "EULA",
    path: "/eula",
    component: EULAPage,
  },
  {
    name: "CookieList",
    path: "/cookie_list",
    component: CookieListPage,
  },
  {
    name: "Public",
    path: "/public",
    component: {
      render: (createElement) => createElement("router-view"),
    },
    children: [
      {
        name: "PublicContentPage",
        path: "content_items/:token",
        component: PublicContentPage,
        props: true,
      },
    ],
  },
  // Keep catchall route at the end of routes
  { path: "/:pathMatch(.*)*", component: NotFoundPage },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to: Route, from: Route, next: Function) => {
  if (to.name === "AddUserFromToken" || to.path.startsWith("/signup")) {
    // Allow sign-up only when not logged in
    if (!store.getters.currentUser) {
      next();
    }
  } else if (to.name === "VerifyEmail" && store.getters.currentUser) {
    next("/");
  } else if (
    to.name === "ResetPassword" ||
    to.name === "VerifyEmail" ||
    to.name === "PrivacyStatement" ||
    to.name === "CookieList" ||
    to.name === "EULA"
  ) {
    next();
  } else if (to.name === "Login" && !!store.getters.currentUser) {
    next("/"); // If already logged in, redirect to home
  } else if (
    !to.fullPath.startsWith("/sessions/") &&
    !to.fullPath.startsWith("/public/") &&
    !store.getters.currentUser
  ) {
    next({ name: "Login", query: to.path !== "/" ? { redirect: to.path } : null });
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.meta.analyticsEvent !== false) {
    new AnalyticsEventsService().trackPageView(to.fullPath, to.name);
  }
  try {
    const snowplowService: SnowplowService = new SnowplowService();
    snowplowService.setUserId(store.getters?.currentUser?.id);
    snowplowService.trackPageView();
  } catch (error) {
    console.error(`Snowplow error: ${error.message}`);
  }
});

export default router;
